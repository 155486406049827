
 .second_col{
    margin-right: auto;
 }
 .row{
    justify-content: center; 
    display: flex;
    flex-direction: column;
    min-height: 100vh;
 }
 